import React, { useState, useEffect, useRef } from "react";
import { gsap } from "gsap";
import { qsa } from "../utils/lib";

const ProductGallery = ({ product }) => {
  const tl = gsap.timeline();
  const [images, setImages] = useState([]);
  const [active, setActive] = useState(0);

  useEffect(() => {
    (async() => {
        const req = await fetch(`/api/product_images/${product.slug}`)
        const res = await req.json()
        setImages(res)
    })()
  },[])

  const animation = (reverse, params) => {
    gsap.to([qsa(".carousel_btn")], {
      pointerEvents: "none",
    });

    tl.to(".js-carousel-img", {
      x: reverse ? "30vw" : "-30vw",
      duration: 0.5,
      opacity: 0,
      ease: "Expo.easeIn",
    });

    tl.set(".js-carousel-img", {
      x: reverse ? "-30vw" : "30vw",
      duration: 0,
      onComplete: () => setActive(params),
    });
  };

  const imgLoaded = () => {
    tl.to(".js-carousel-img", {
      x: 0,
      opacity: 1,
      duration: 0.5,
      delay: 0.1,
      ease: "Expo.easeOut",
      onComplete: () => {
        gsap.to([qsa(".carousel_btn")], {
          pointerEvents: "all",
        });
      },
    });
  };

  const nextImg = () => {
    if (active >= images.length - 1) {
      animation(false, 0);
    } else {
      animation(false, active + 1);
    }
  };

  const prevImg = () => {
    if (active < 1) {
      animation(true, images.length - 1);
    } else {
      animation(true, active - 1);
    }
  };

  return (
    <div className="col no_padding">
      <div className="carousel_wrapper relative">
        {images.length && (
          <div className="image_wrapper hero-host">
            <img
              onLoad={imgLoaded}
              className="js-carousel-img contain"
              src={images[active].image.url}
              alt=""
            />
          </div>
        )}
        <button onClick={nextImg} className="carousel_btn absolute">
          <img src="/images/arrow-carousel.svg" alt="" className="arrow" />
        </button>
        <button onClick={prevImg} className="carousel_btn absolute sx">
          <img src="/images/arrow-carousel.svg" alt="" className="arrow" />
        </button>
        <div className="slider">
          {images.map((slide, i) => {
            return (
              <span
                key={i}
                className={`${i === active ? "active" : ""} slider_el`}
              ></span>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ProductGallery;
