import React from "react";
import ProductGallery from "./product_gallery";
import ProductSidebar from "./product_sidebar";

const ProductPage = ({ products, product, translations, locale}) => {

  const p = JSON.parse(product)
  const tr = JSON.parse(translations)
  const language = locale 


  return (
      
        <section className="product_page grid no_margin bg-productgrey">
          <ProductGallery 
            product={p}
            t={tr} />
          <ProductSidebar 
            singleProduct={p}
            t={tr}
            listProducts={JSON.parse(products)}
            loc={language} />
        </section>
      
  );
};

export default ProductPage;
