import { gsap, ScrollToPlugin } from "gsap/all";
import { qs, qsa } from "../utils/lib";

gsap.registerPlugin(ScrollToPlugin);

const Header = () => {
  const hero = qs(".hero");
  const hero_host = qs(".hero-host");

  if (hero || hero_host) {
    window.onscroll = () => scrollFunction();
  } else {
    qs(".header").classList.add("active");
  }

  const scrollFunction = () => {
    if (document.body.scrollTop > 0 || document.documentElement.scrollTop > 0) {
      qs(".header").classList.add("active");
    } else {
      qs(".header").classList.remove("active");
    }
  };

  qs(".hamburger-menu").addEventListener("click", () => {
    qs(".hamburger-menu-content").classList.add("active");
    qs('html').style.overflow = "hidden"
    qs('body').style.overflow = "hidden"
  });

  qs(".hamburger-menu-content-close").addEventListener("click", () => {
    qs(".hamburger-menu-content").classList.remove("active");
    qs('html').style.overflow = "auto"
    qs('body').style.overflow = "auto"
  });

  const activePage = window.location.pathname;
  qsa(".hamburger-menu-content-list a").forEach((link) => {
    const path = link.dataset.path;
    if (path == activePage) {
      link.classList.add("active");
    }
  });

  if (qs(".shop")) qs(".collection").classList.add("highlight");
  if (qs(".specifications")) qs(".spec").classList.add("highlight");

};
export default Header;
