import React, {useState, useEffect} from "react";

const ProductSidebar = ({singleProduct, t, listProducts, loc}) => {
    const [product, setProduct] = useState({})
    useEffect(() => {
        (async() => {
            const req = await fetch(`/api/product/${singleProduct.slug}`)
            const res = await req.json()
            setProduct(res)
        })()
    },[])
  const handleChange = (e) => {
    const goHere = listProducts.find((el) => el.title === e.target.value);
    window.location.href = goHere.slug.toLowerCase();
  };

  return (
    <div className="col product_page_select">
      <div className="jumbotron_inner grid no_margin">
        <p className="breadcrumbs c-dark">
          <a className="c-dark h8" href="/collection">
            {t.bread_collection}
          </a>{" "}
          / <span className="bold">{t.bread_choose}</span>
        </p>
      </div>
      <div className="product_page_select_menu">
       
        <div className="arrow">
          <img src="/images/arrow-select.svg" alt="" />
        </div>
        {listProducts?.length !== 0 && singleProduct && (
          <select
            onChange={(e) => handleChange(e, listProducts)}
            defaultValue={product.title}
            className="h6 medium"
            name={product.title}
          >
            {listProducts?.map((p) => {
              return (
                <option value={p.title} key={p.id} selected={p.title == singleProduct.title ? "selected" : ""}>
                  {p.title}
                </option>
              );
            })}
          </select>
        )}
      </div>
      <div className="text-padding-bottom-1">
        <p className="c-dark medium h6 text-padding-bottom-1">{t.side_panel}</p>
        <p className="c-dark h7 text-padding-bottom-1 capitalize">
          {singleProduct && singleProduct.side_panel}
        </p>
      </div>
      <div className="text-padding-bottom-1">
        <p className="medium c-dark h6 text-padding-bottom-1">{t.slab}</p>
        <p className="c-dark h7 text-padding-bottom-1 capitalize">
          {singleProduct && singleProduct.slab}
        </p>
      </div>
      <div className="text-padding-bottom-1">
        <p className="medium c-dark h6 text-padding-bottom-1">{t.drip_tray}</p>
        <p className="c-dark h7 text-padding-bottom-1 capitalize">
          {singleProduct && singleProduct.drip_tray}
        </p>
      </div>
      {
        product?.links && (
            <a href={product.links[loc]} target="_blank">
                <button className="h8 button wr upcase medium text-center bg-accent c-light">
                {t.request_cta}
                </button>
            </a>
        )
      }
    </div>
  );
};

export default ProductSidebar;
